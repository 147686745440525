.slideshow-wrap {
    position: relative;
    background-color: $bg-color-body;

    #layerslider {
        display: block;
    }

    /*
    #layerslider-mobile {
        display: none; 
    }
    */
    @media screen and (max-width: 991px) {
        #layerslider {
            display: none;
        }

        #layerslider-mobile {
            display: block;
            width: 991px;
            height: 795px;
        }
    }

    @media screen and (max-width: 768px) {
        #layerslider-mobile {
            width: 991px;
            height: 991px;
        }
    }

    @media screen and (max-width: 520px) {
        #layerslider-mobile {
            width: 991px;
            height: 1395px;
        }
    }

    .slideshow-next {
        right: 50px;
    }

    .slideshow-prev {
        left: 50px;
    }

    .subtitle {
        color: $color-white;
        letter-spacing: 0.3rem;
        text-transform: uppercase;
        font-size: $font-size-24;
        text-align: center;
        padding-bottom: 0.5rem;
    }

    .title {
        //@extend .font-2-normal;
        text-transform: uppercase;
        color: $color-primary;
        font-size: $font-size-50;
        text-align: center;
    }

    .ls-l a {
        width: auto;
    }

    .caption.container-fluid {
        top: 8vw;
        left: 0;
        right: 0;
        bottom: 5vw;
        display: flex;
        position: absolute;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        // top: $size-nav-height + 50px;
        z-index: $z-index-slide-caption;

        // margin-top: 4.5vw;
        @media screen and (max-width: $size-900-max) {
            top: 14vw;
            bottom: 0;
        }

        @media screen and (max-width: $size-650-max) {
            top: 8vw;
            bottom: 0;
        }

        @media screen and (max-width: $size-480-max) {
            padding: 0;
        }

        .row {
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: center;

            .title-slider {
                font-size: 1.8rem;
                font-weight: 500;
                font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
                text-transform: none;
                color: $color-white;
                margin-bottom: 0;

                @media screen and (max-width: $size-900-max) {
                    font-size: 1.4rem;
                }
            }

            .title-slider-02 {
                font-size: .8rem;
                font-weight: 400;
                font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
                text-transform: none;
                color: $color-6;
                margin-bottom: 0;
            }

            .content-text-slider {
                width: 100%;
                display: flex;
                align-items: center;
                justify-content: center;
                margin-top: 2vw;

                @media screen and (max-width: $size-900-max) {
                    margin-top: 2px;
                }

                @media screen and (max-width: $size-650-max) {
                    display: none;
                }

                p {
                    color: $color-white;
                    font-family: "Ubuntu";
                    line-height: 1.0rem;
                    font-size: .7rem;
                    font-weight: 400;
                }
            }

            .line-search-engine {
                width: 100%;
                display: flex;
                align-items: center;
                justify-content: center;
                flex-direction: column; 
                @media screen and (max-width: $size-900-max) {
                    flex-wrap: wrap;
                }

                .col-md-12 {
                    width: 100%;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                }

                .filter-price {
                    width: 100px;
                    max-width: 100px;
                }

                .form-group {
                    margin: 2px;
                    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;                     
                    @media screen and (max-width: 991px) {
                        width: 100%; 
                    }
                    
                    @media screen and (max-width: $size-900-max) {
                        &:nth-child(1) {
                            // width: 85% !important; 
                            .label-search {
                                display: none;
                            }
                        }

                        /*
                        &:nth-child(2) {
                            width: 20%;
                            //min-width: 150px;
                            @media screen and (max-width: $size-1024-max) {
                                width: 27%;
                            }
                        }
                        &:nth-child(3) {
                            width: 20%;
                            //min-width: 150px;
                            @media screen and (max-width: $size-1024-max) {
                                width: 27%;
                            }
                        }
                        &:nth-child(4) {
                            width: 20%;
                            //min-width: 150px;
                            @media screen and (max-width: $size-1024-max) {
                                width: 27%;
                            }
                        }
                        */
                        &:nth-child(5) {
                            width: 20%;
                            // min-width: 150px;
                        }

                        &:nth-child(6) {
                            width: 75%;
                            display: flex;
                            justify-content: center;

                            .btn-search {
                                margin-top: 0;
                                width: 100px; 
                                max-width: 85px; 
                            }
                        }
                    }

                    .content-btn-slider {
                        display: flex;
                        align-items: center;
                        justify-content: flex-start;

                        /* The container */
                        .container {
                            margin: 0;
                            width: 145px;
                            display: flex;
                            align-items: center;
                            justify-content: flex-start;
                            position: relative;
                            padding-left: 35px;
                            margin-bottom: 8px;
                            color: $color-white;
                            font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
                            cursor: pointer;
                            font-size: .8rem;
                            -webkit-user-select: none;
                            -moz-user-select: none;
                            -ms-user-select: none;
                            user-select: none;
                        }

                        /* Hide the browser's default checkbox */
                        .container input {
                            position: absolute;
                            opacity: 0;
                            cursor: pointer;
                            height: 0;
                            width: 0;
                        }

                        /* Create a custom checkbox */
                        .checkmark {
                            top: -2px;
                            left: 0;
                            height: 25px;
                            width: 32px;
                            border-radius: 1px;
                            position: absolute;
                            background-color: $color-5;
                            transition: .5s;
                        }

                        /* On mouse-over, add a grey background color */
                        .container:hover input~.checkmark {
                            background-color: $color-4;
                            transition: .5s;
                        }

                        /* When the checkbox is checked, add a blue background */
                        .container input:checked~.checkmark {
                            background-color: $color-2;
                            transition: .5s;
                        }

                        /* Create the checkmark/indicator (hidden when not checked) */
                        .checkmark:after {
                            content: "";
                            position: absolute;
                            display: none;
                        }

                        /* Show the checkmark when checked */
                        .container input:checked~.checkmark:after {
                            display: block;
                            transition: .5s;
                        }

                        /* Style the checkmark/indicator */
                        .container .checkmark:after {
                            left: 11px;
                            top: 7px;
                            width: 5px;
                            height: 10px;
                            border: solid $color-white;
                            border-width: 0 11px 11px 0;
                            -webkit-transform: rotate(45deg);
                            -ms-transform: rotate(45deg);
                            transform: rotate(45deg);
                        }
                    }

                    .label-search {
                        font-weight: 500;
                        color: $color-white;
                        font-size: .7rem;
                        margin-bottom: 0;
                    }

                    .btn-search {
                        margin-top: 27px; 
                        max-width: 85px; 
                    }

                    &:nth-child(1) {
                        // width: 35%;
                    }

                    select,
                    input[type=text],
                    .nice-select {
                        border-radius: 1px;
                        height: 47px;
                        transition: .5s;

                        @media screen and (max-width: $size-1024-max) {
                            // : 40px;
                            font-size: 15px;
                            padding: 2px 12px;
                            transition: .5s;
                        }

                        @media screen and (max-width: $size-900-max) {
                            //height: 36px;
                            width: 100%;
                            transition: .5s;
                            padding: 2px 8px;
                            overflow: hidden;
                        }
                    }

                    input[type=text] {
                        //font-size: .8rem;
                        color: $color-grey-darker;

                        @media screen and (max-width: $size-1024-max) {
                            //font-size: 13px;
                        }
                    }

                    select {
                        padding: 0 10px;
                        font-size: .7rem;

                        @media screen and (max-width: $size-1024-max) {
                            font-size: 13px;
                        }
                    }
                }

                .content-search-btn {
                    display: flex; 
                    align-items: center; 
                    justify-content: center; 
                    max-width: 85px; 
                    .btn-search {
                        background: $color-2;
                        color: $color-white;
                        padding: 6px 25px;
                        margin-top: 0px;
                        cursor: pointer;
                        transition: .3s;
                        border: 0;

                        @media screen and (max-width: $size-1024-max) {
                            height: 40px;
                            font-size: 15px;
                            padding: 2px 12px;
                            transition: .5s;
                            margin-top: -8px;
                            border-radius: 3px;
                            width: 100%;
                            display: flex;
                            align-items: center;
                            justify-content: center;
                        }

                        &:hover {
                            background-color: $color-grey-darker;
                            transition: .3s;
                        }
                    }
                }
            }

            @media screen and (max-width: $size-sm-min) {
                .col-md-12 {
                    padding: 0;
                    flex-wrap: wrap;
                    max-width: 100%; 

                    .form-group:nth-child(1) {
                        width: 100% !important;
                    }

                    /*
                    .form-group:nth-child(2) {
                        width: 30% !important;
                    }
                    .form-group:nth-child(3) { 
                        width: 30% !important; 
                    }
                    .form-group:nth-child(4) { 
                        width: 30% !important; 
                    }
                    .form-group:nth-child(5) { 
                        width: 45% !important; 
                    }
                    */
                    .form-group.filter-price {
                        min-width: 45%;
                    }
                }
            }
        }

        .content-text-field {
            width: 50%;
        }
        .content-fields-search {
            width: 35vw; 
            display: flex; 
            align-items: center; 
            justify-content: flex-start; 
            // flex-wrap: wrap; 
            @media screen and (max-width: 991px) {
                width: 100%; 
                margin: 5px 0; 
                justify-content: center; 
            }
            .field-01 {
                width: 100%; 
                min-width: 95px!important;
                margin: 0 2px; 
                .field-01-inner, 
                select {
                    width: 100%; 
                    min-width: 85px!important; 
                    border: 1px solid transparent; 
                    overflow: hidden; 
                    height: 47px; 
                }
            }            

            @media screen and (max-width: 768px) { 
                .field-01 {
                    width: 32%!important; 
                    min-width: 95px!important;
                    margin: 0 2px; 
                    .field-01-inner, 
                    select {
                        width: 100%; 
                        min-width: 85px!important; 
                        border: 1px solid transparent; 
                        overflow: hidden; 
                        height: 47px; 
                    } 
                } 
            }
        }
    }

    .nice-select {
        display: none;
    }

    #state_prov,
    #tp_prop,
    #c_p {
        display: block !important;
    }

    .caption.container-fluid {

        /*the container must be positioned relative:*/
        .autocomplete {
            position: relative;
            display: inline-block;
        }

        input {
            border: 1px solid transparent;
            background-color: #f1f1f1;
            padding: 10px;
            font-size: 16px;
        }

        input[type=text] {
            background-color: #f1f1f1;
            width: 100%;
        }

        input[type=submit] {
            background-color: DodgerBlue;
            color: #fff;
            cursor: pointer;
        }

        .autocomplete-items {
            position: absolute;
            border: 1px solid #d4d4d4;
            border-bottom: none;
            border-top: none;
            z-index: 99;
            /*position the autocomplete items to be the same width as the container:*/
            top: 100%;
            left: 0;
            right: 0;
        }

        .autocomplete-items div {
            padding: 10px;
            cursor: pointer;
            background-color: #fff;
            border-bottom: 1px solid #d4d4d4;
        }

        /*when hovering an item:*/
        .autocomplete-items div:hover {
            background-color: #e9e9e9;
        }

        /*when navigating through the items using the arrow keys:*/
        .autocomplete-active {
            background-color: DodgerBlue !important;
            color: #ffffff;
        }

        form.form-row {
            width: 95% !important; 
            margin: 0;
            padding: 0;
            display: flex;
            align-items: center;
            justify-content: center;
        }
    }

    @media screen and (max-width: $size-xs-max) {
        .ls-l a {
            font-size: $font-size-10;
            border-width: 1px;
            padding: 0.25rem;
        }
    }

    @media screen and (max-width: $size-slider-breakpoint) {

        //.title {
        //    font-size: 26px;
        //}
        //.subtitle {
        //    font-size: 16px;
        //}
        .caption {
            top: $size-nav-height-mobile + 20px;
        }
    }

    @media screen and (max-width: $size-900-max) {
        .ls-gui-element.ls-bottom-nav-wrapper {
            display: none;
        }
    }
}
// IMPORT ADDONS
//@import '../vendors/bootstrap/css/bootstrap';
//@import '../vendors/font-awesome/font-awesome';

/* purgecss start ignore */

// @import '../vendors/owl-carousel2/owl.carousel'; 
// @import '../vendors/magnific-popup/magnific-popup';
// @import '../vendors/cube-portfolio/cubeportfolio';
@import 'styles/alertify'; 

/* purgecss end ignore */

// IMPORT CUSTOMIZATION & RESET (order is important)
//@import 'core/reset';
@import 'variables';
@import 'core/html';
//@import 'utilities/functions';
// *******************
// Load basic classes (besoin de rester ici à la fin)
//@import 'styles/fonts'; // utility classes for fonts
@import 'utilities/spacing'; // utility classes for spacing
@import 'utilities/flex'; // utility classes for flex box
@import 'utilities/responsive';
@import 'utilities/animation';
@import 'styles/colors'; // utility classes for colors
@import 'styles/hover';
//@import 'styles/typography';
//@import 'styles/buttons';

/* GENERAL TEMPLATE */

html {
    width: 100%;
    max-width: 100%;
    height: 100%;
}

.sect-0.index {
    padding: 15px 15px;
    padding-bottom: 2.2vw;
    border-top: 15px solid $color-2;

    // border-bottom: 15px solid $color-2;
    .container-fluid {
        h3 {
            color: $color-2;
            font-weight: 400;
            margin-right: 1rem;
            text-transform: initial;
            font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
            font-size: 1.3rem;
        }

        .content-body-vedette {
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
            flex-wrap: wrap;
        }

        .left-vedette {
            width: 100%;
            display: flex;
            align-items: flex-start;
            justify-content: space-between;
            flex-wrap: wrap;

            .box-vedette-sect-0 {
                width: 16.555%;
                display: flex;
                align-items: center;
                justify-content: center;
                border: 1px solid $color-4;
                flex-direction: column;
                border-radius: 1px;
                position: relative;
                margin-bottom: 2px;
                transition: .5s;
                overflow: hidden;

                @media screen and (max-width: $size-1150-max) {
                    width: 32.8%;
                    margin-bottom: 6px;
                }

                @media screen and (max-width: $size-650-max) {
                    width: 49.4%;
                    margin-bottom: 6px;
                }

                .content-cover-sect-0 {
                    width: 100%;
                    height: 10vw;
                    display: flex;
                    align-items: center;
                    flex-direction: column;
                    justify-content: center;
                    border: 1 solid $color-4;
                    overflow: hidden;

                    @media screen and (max-width: $size-1150-max) {
                        height: 18vw;
                    }

                    @media screen and (max-width: $size-650-max) {
                        height: 32vw;
                    }

                    .cover-vedette {
                        width: 150vw;
                        max-width: 150% !important;
                    }
                }

                .official-notice {
                    top: -6.3vw;
                    left: 9.2vw;
                    display: none;
                    align-items: flex-end;
                    justify-content: center;
                    position: absolute;
                    text-align: center;
                    transform: rotate(45deg);
                    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
                    color: $color-white;
                    background: $color-2;
                    font-size: 13px;
                    font-weight: 500;
                    width: 100%;
                    height: 100%;
                    padding: 0;
                }

                .description-vedette {
                    width: 100%;
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    align-items: flex-start;
                    border: 0px solid $color-4;
                    background: $bg-color-nav;
                    position: absolute;
                    padding: 5px 15px;
                    bottom: 0;

                    span {
                        text-align: left;
                        color: $color-white;
                        font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
                        transition: .5s;
                    }

                    .city-state {
                        font-size: 11px;
                    }

                    .price-sect-0 {
                        font-size: 12px;
                        font-weight: 600;
                    }
                }

                &:hover {
                    transition: .5s;

                    .description-vedette {
                        span {
                            color: $color-2;
                            transition: .5s;
                        }
                    }
                }
            }
        }

        .right-vedette {
            width: 100%;
            padding: 15px 2.5vw;

            h3 {
                font-size: 2.0rem;
                line-height: 2.2rem;
                text-transform: none;
                font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
                color: $color-grey-darker;
                margin-bottom: 22px;
            }

            p {
                font-size: .9rem;
                font-weight: 300;
                color: $color-grey;
                line-height: 1.4rem;
                margin-bottom: 1vw;
                font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
            }
        }
    }
}

.sect-01.index {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-direction: column;
    margin-bottom: 65px;

    .container-fluid {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        flex-direction: column;

        .header-section {
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: flex-start;
            font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
            flex-direction: column;
            padding: 20px 15px;

            .title-sect-01 {
                font-weight: 400;
                font-size: 1.4rem;
                text-transform: none;
                color: $color-2;
                font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
            }
        }

        .section-body {
            width: 100%;
            display: flex;
            align-items: flex-start;
            justify-content: space-between;
            font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
            flex-wrap: wrap;

            .box-propriety {
                width: 24.777%;
                border: 1px solid $color-4;
                // margin-bottom: 5px; 
                margin-bottom: .33333vw;

                @media screen and (max-width: $size-1150-max) {
                    width: 33.222%;
                }

                @media screen and (max-width: $size-650-max) {
                    width: 48.5%;
                    margin-bottom: 2.33333vw;
                }

                @media screen and (max-width: $size-450-max) {
                    width: 100%;
                }

                .content-cover {
                    display: flex;
                    max-height: 14vw;
                    overflow: hidden;
                    align-items: center;
                    justify-content: center;
                    position: relative;

                    @media screen and (max-width: $size-1150-max) {
                        max-height: 17vw;
                    }

                    @media screen and (max-width: $size-650-max) {
                        max-height: 32vw;
                        // max-height: 58vw;
                    }

                    @media screen and (max-width: $size-450-max) {
                        max-height: 58vw;
                    }

                    span {
                        top: 10px;
                        right: 15px;
                        font-weight: 500;
                        font-size: .8rem;
                        position: absolute;
                        background: $color-white;
                        border-radius: 2px;
                        color: $color-2;
                        padding: 5px;

                        @media screen and (max-width: $size-900-max) {
                            font-size: .9rem;
                        }
                    }
                }

                .bottom-propriety {
                    width: 100%;
                    display: flex;
                    justify-content: flex-start;
                    flex-direction: column;
                    padding: 8px;

                    .title-propriety {
                        height: 40px;
                        color: $color-2;
                        text-transform: none;
                        font-family: "Ubuntu";
                        align-items: center;
                        font-weight: 500;
                        font-size: .8rem;
                        line-height: 1.0rem;
                        transition: .5s;
                        display: flex;

                        @media screen and (max-width: $size-1150-max) {
                            height: 30px;
                            font-size: .9rem;
                        }

                        @media screen and (max-width: $size-650-max) {
                            height: auto; 
                            font-size: 18px; 
                            padding: 10px 0; 
                        }
                        &:hover {
                            color: $color-1;
                            transition: .5s;
                        }
                    }

                    span {
                        font-weight: 400;
                        font-size: .7rem;
                        line-height: 1.0rem;
                        color: $color-grey-dark;

                        @media screen and (max-width: $size-900-max) {
                            font-size: 16px; 
                            padding-top: 0px; 
                        }

                        @media screen and (max-width: $size-xs-max) {
                            font-size: 11px;
                        }
                    }

                    .inner-top {
                        display: flex;
                        padding: 7px 0;
                        margin-top: 8px;
                        padding-bottom: 0;
                        border-top: 1px solid $color-4;
                        justify-content: space-between;
                        align-items: center;
                        height: 40px;

                        .icon-inner-01 {
                            margin: 5px;
                            padding: 5px;
                            border: 0 solid $color-2;
                            color: $color-grey-darker;
                            display: flex;
                            align-items: center;
                            justify-content: space-around;

                            &:hover {
                                color: $color-grey-dark;
                            }

                            span {
                                font-weight: 500;
                                font-size: 12px;
                            }

                            img {
                                width: 35px;
                            }
                        }
                    }
                }
            }
        }
    }
}

.caption.container-fluid {
    top: 8vw;
    left: 0;
    right: 0;
    bottom: 5vw;
    display: flex;
    position: absolute;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    // top: $size-nav-height + 50px;
    z-index: $z-index-slide-caption;

    // margin-top: 4.5vw;
    @media screen and (max-width: $size-900-max) {
        top: 14vw;
        bottom: 0;
    }

    @media screen and (max-width: $size-650-max) {
        top: 8vw;
        bottom: 0;
    }

    .row {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;

        .title-slider {
            font-size: 1.8rem;
            font-weight: 500;
            font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
            text-transform: none;
            color: $color-white;
            margin-bottom: 0;

            @media screen and (max-width: $size-900-max) {
                font-size: 1.4rem;
            }
        }

        .title-slider-02 {
            font-size: .8rem;
            font-weight: 400;
            font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
            text-transform: none;
            color: $color-6;
            margin-bottom: 0;
        }

        .content-text-slider {
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
            margin-top: 2vw;

            @media screen and (max-width: $size-900-max) {
                margin-top: 2px;
            }

            @media screen and (max-width: $size-650-max) {
                display: none;
            }

            p {
                color: $color-white;
                font-family: "Ubuntu";
                line-height: 1.0rem;
                font-size: .7rem;
                font-weight: 400;
            }
        }

        .line-search-engine {
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
            flex-wrap: wrap;

            @media screen and (max-width: $size-900-max) {
                flex-wrap: wrap;
            }

            .form-group {
                margin: 2px;
                font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif; 
                @media screen and (max-width: $size-900-max) {
                    &:nth-child(1) {
                        width: 100% !important; 
                        .label-search {
                            display: none; 
                        }
                    }
                    /*
                    &:nth-child(2) {
                        width: 20%;
                    }

                    &:nth-child(3) {
                        width: 20%;
                    }

                    &:nth-child(4) {
                        width: 20%;
                    }

                    &:nth-child(5) {
                        width: 20%;
                    }
                    */ 
                    &:nth-child(6) {
                        width: 75%;
                        display: flex;
                        justify-content: center;

                        .btn-search {
                            margin-top: 0;
                            width: 100px;
                        }
                    }
                }

                .content-btn-slider {
                    display: flex;
                    align-items: center;
                    justify-content: flex-start; 
                    /* The container */
                    .container {
                        margin: 0;
                        width: 145px;
                        display: flex;
                        align-items: center;
                        justify-content: flex-start;
                        position: relative;
                        padding-left: 35px;
                        margin-bottom: 8px;
                        color: $color-white;
                        font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
                        cursor: pointer;
                        font-size: .8rem;
                        -webkit-user-select: none;
                        -moz-user-select: none;
                        -ms-user-select: none;
                        user-select: none;
                    }

                    /* Hide the browser's default checkbox */
                    .container input {
                        position: absolute;
                        opacity: 0;
                        cursor: pointer;
                        height: 0;
                        width: 0;
                    }

                    /* Create a custom checkbox */
                    .checkmark {
                        top: -2px;
                        left: 0;
                        height: 25px;
                        width: 32px;
                        border-radius: 1px;
                        position: absolute;
                        background-color: $color-5;
                        transition: .5s;
                    }

                    /* On mouse-over, add a grey background color */
                    .container:hover input~.checkmark {
                        background-color: $color-4;
                        transition: .5s;
                    }

                    /* When the checkbox is checked, add a blue background */
                    .container input:checked~.checkmark {
                        background-color: $color-2;
                        transition: .5s;
                    }

                    /* Create the checkmark/indicator (hidden when not checked) */
                    .checkmark:after {
                        content: "";
                        position: absolute;
                        display: none;
                    }

                    /* Show the checkmark when checked */
                    .container input:checked~.checkmark:after {
                        display: block;
                        transition: .5s;
                    }

                    /* Style the checkmark/indicator */
                    .container .checkmark:after {
                        left: 11px;
                        top: 7px;
                        width: 5px;
                        height: 10px;
                        border: solid $color-white;
                        border-width: 0 11px 11px 0;
                        -webkit-transform: rotate(45deg);
                        -ms-transform: rotate(45deg);
                        transform: rotate(45deg);
                    }
                }

                .label-search {
                    font-weight: 500;
                    color: $color-white;
                    font-size: .7rem;
                    margin-bottom: 0;
                }

                .btn-search {
                    margin-top: 27px;
                }
                /*
                &:nth-child(1) {
                    width: 35%;
                }
                */ 
            }
        }
    }
}

.results-search {
    .mb20 {
        margin-bottom: 20px;
    }

    /*
    hgroup { padding-left: 15px; border-bottom: 1px solid #ccc; }
    hgroup h1 { font: 500 normal 1.625em "Roboto",Arial,Verdana,sans-serif; color: #2a3644; margin-top: 0; line-height: 1.15; }
    hgroup h2.lead { font: normal normal 1.125em "Roboto",Arial,Verdana,sans-serif; color: #2a3644; margin: 0; padding-bottom: 10px; }

    .search-result .thumbnail { border-radius: 0 ; }
    .search-result:first-child { margin-top: 0 ; }
    .search-result { margin-top: 20px; }
    .search-result .col-md-2 { border-right: 1px dotted #ccc; min-height: 140px; }
    .search-result ul { padding-left: 0 ; list-style: none;  }
    .search-result ul li { font: 400 normal .85em "Roboto",Arial,Verdana,sans-serif;  line-height: 30px; }
    .search-result ul li i { padding-right: 5px; }
    .search-result .col-md-7 { position: relative; }
    .search-result h3 { font: 500 normal 1.375em "Roboto",Arial,Verdana,sans-serif; margin-top: 0; margin-bottom: 10px; }
    .search-result h3 > a, .search-result i { color: #b17e00 ; }
    .search-result h4 { 
        font-size:16px;
        font-weight: 400;
        color: #b17e00 ; 
    }
    .search-result p { font: normal normal 1.125em "Roboto",Arial,Verdana,sans-serif; } 
    .search-result span.plus { position: absolute; right: 0; top: 126px; }
    .search-result span.plus a { background-color: #b17e00; padding: 5px 5px 3px 5px; }
    .search-result span.plus a:hover { background-color: #414141; }
    .search-result span.plus a i { color: #fff ; }
    .search-result span.border { display: block; width: 97%; margin: 0 15px; border-bottom: 1px dotted #ccc; }
    */
    .content-results {
        padding: 30px 15px; 
    }

    .sub-title-result {
        font-size: 15px; 
        font-weight: 400; 
        color: $color-2;
        span {
            color: $color-grey-dark; 
        }
    }

    .mb20 {
        width: 100%;
        border-bottom: 1px solid $color-grey-light;

        h1 {
            font-size: 1.8rem;
        }

        h2 {
            font-size: .9rem;
            color: $color-grey;
            text-transform: lowercase;
        }
    }

    .search-result {
        padding-bottom: 35px;
        border-bottom: 1px solid $color-grey-light;

        .prix-result {
            font-size: 1.2rem;
            color: $color-grey;
        }

        .title-result {
            font-size: 1.2rem;
            color: $color-2;
        }

        .description-result {
            font-size: 14px;
            color: $color-grey;
            border-top: 1px solid #ccc;
            border-bottom: 1px solid #ccc;
            padding: 10px 0;
        }

        .address-result {
            color: $color-grey;
            font-weight: 300;
            font-size: 14px;
            padding: 10px 0;
        }
    }

    .prix-result {
        width: 100%;
        text-align: left;
    }
}



.results-search {
    .section-filter {
        width: 100%;
        height: 80px;
        display: flex;
        align-items: center;
        justify-content: center;
        background: #ca9000;
        padding-bottom: 12px;
    }

    .section-filter .container-fluid {
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-top: 25px;
    }

    .section-filter .container-fluid .form-row {
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .section-filter .container-fluid .form-row label {
        color: #fff;
        font-family: arial;
        font-weight: 500px;
    }

    .section-filter .container-fluid .form-row input[type=text],
    input[type=datetime-local],
    input[type=date] {
        min-width: 100px;
        border-radius: 1px;
        height: 40px;
    }

    .section-filter .container-fluid .form-row button {
        min-width: 100px;
        margin-top: 20px;
        border-radius: 1px;
        height: 40px;
    }

    .section-filter .container-fluid .form-row select {
        min-width: 100px;
    }

    @media screen and (max-width: 850px) {
        .section-filter {
            height: auto;
            flex-wrap: wrap;
        }

        .section-filter .container-fluid {
            height: auto;
            flex-wrap: wrap;
        }

        .section-filter form {
            height: auto;
            flex-wrap: wrap;
        }

        .section-filter form .form-row {
            height: auto;
            flex-wrap: wrap;
        }

        .section-filter form .form-row .form-group {
            width: 50%;
        }
    }
}


@media screen and (max-width: $size-xs-max) {
    .ls-l a {
        font-size: $font-size-10;
        border-width: 1px;
        padding: 0.25rem;
    }
}

@media screen and (max-width: $size-slider-breakpoint) {

    //.title {
    //    font-size: 26px;
    //}
    //.subtitle {
    //    font-size: 16px;
    //}
    .caption {
        top: $size-nav-height-mobile + 20px;
    }
}

@media screen and (max-width: $size-900-max) {
    .ls-gui-element.ls-bottom-nav-wrapper {
        display: none;
    }
}

.header-estimer-vendre {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center; 
    flex-direction: column; 
    margin-bottom: 10px; 
    margin-top: 35px;

    .container-box {
        width: 100%; 
        display: flex; 
        align-items: center; 
        justify-content: center; 
        flex-direction: column; 
        .container-box {
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
            // flex-direction: column;             
        }
        h3 {
            width: 100%; 
            text-align: center; 
            font-size: 1.4rem; 
        }
        .box-buttons {
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: center; 
            label {
                display: flex;
                align-items: center;
                justify-content: center;
                flex-direction: column;
                font-size: 18px;
                font-weight: 500;
                color: $color-grey-dark;
                margin: 5px 12px;
                border: 1px solid $color-grey-light;
                padding: 15px;
                width: 165px;
                background: $color-white;
                border-radius: 2px;
                @media screen and (max-width: 678px) {
                    font-size: 15px;
                    font-weight: 500;
                    color: #353535;
                    margin: 5px 5px;
                }
            } 
        }
    }
}

.tab-nav-form-submission {
    margin-bottom: 55px;

    #exTab1 ul.nav.nav-pills {
        width: 100%;
        height: 50px;
        display: flex;
        align-items: center;
        justify-content: flex-start; 
        li {
            width: 50%; 
            height: 50px; 
            padding: 10px 12px; 
            border: 1px solid $color-grey-light; 
            border-bottom: 0; 
            padding: 8px 5px; 
            &:nth-child(1) {
                border-right: 0px solid $color-grey-light;
            }
            &.active {
                background-color: $color-2;                
            } 
            a {
                height: 100%;
                display: flex;
                align-items: center;
                justify-content: flex-start;
                font-family: "Ubuntu";
                margin-right: 12px;
                font-weight: 500;
                font-size: 17px;
                color: $color-grey-darker; 
                &:hover {
                    color: $color-grey; 
                    transition: .5s;
                }
                @media screen and (max-width: 768px) {
                    font-size: 15px; 
                    justify-content: center; 
                    text-align: center; 
                    line-height: 18px; 
                }
            }
        }

    }

    .title-inn-form {
        margin-top: 15px;
        margin-bottom: 15px;
        color: $color-grey-dark;
        font-size: 1.2rem;
    }

    .form-control {
        border-radius: 0; 
        font-size: 17px; 
        height: 42px; 
    }

    .input-section label {
        display: flex; 
        font-weight: 500; 
        font-family: "Ubuntu"; 
        color: $color-grey-dark; 
        font-size: 15px; 
        margin-bottom: 0; 
    }

    #exTab1 .tab-content {
        color: white;
        border: 1px solid $color-grey-light;
        //background-color: #428bca;
        padding: 5px 15px;
    }

    #exTab2 h3 {
        color: white;
        //background-color: #428bca;
        padding: 5px 15px;
    }

    /* remove border radius for the tab */

    #exTab1 .nav-pills>li>a {
        border-radius: 0;
    }

    /* change border radius for the tab , apply corners on top*/

    #exTab3 .nav-pills>li>a {
        border-radius: 4px 4px 0 0;
    }

    #exTab3 .tab-content {
        color: white;
        // background-color: #428bca;
        padding: 5px 15px;
    }     
}

.section-form {
    .footer-form-content {
        width: 100%; 
        display: flex; 
        align-items: center; 
        justify-content: center; 
        flex-direction: column; 
        .col-xs-12.col-sm-4.form-group {
            width: 100%; 
            display: flex; 
            align-items: center; 
            justify-content: center; 
            flex-direction: column; 
        }
    }
}

.contact-page-area {
    padding-top: 65px; 
    .contact-details-inner {
        .logo-footer {} 
    }  
    .contact-page-map {
        margin-top: 45px; 
        border-top: 15px solid $color-2; 
    }  
}

.form-contact-page {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column; 
    .contact-form-title {
        color: $color-grey-dark; 
        font-size: 1.5rem; 
        font-weight: 500; 
    }
    .form-group {
        label {
            margin-bottom: 0; 
        }         
        input[type=text], 
        input[type=phone], 
        input[type=email], 
        input[type=tel], 
        textarea {
            min-height: 45px;
            border-radius: 1px; 
            border: 1px solid $color-grey-light; 
            font-size: .8rem; 
        }
    }
}

.border-orange {
    padding: 25px 15px; 
    border-left: 1px solid $color-2; 
}

.result {
    color: $color-grey-dark; 
}

.btn-success.disabled, .btn-success:disabled,
.btn-success {
    color: $color-black; 
    background-color: $color-2; 
    transition: .5s; 
    &:hover {
        background-color: $color-10; 
        transition: .5s;
    }
}

.content-checkbox {

    /* The container */
    .container {
        display: block;
        position: relative;
        padding-left: 35px;
        margin-bottom: 12px;
        cursor: pointer;
        font-size: 22px;
        -webkit-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;
    }

    /* Hide the browser's default radio button */
    .container input {
        position: absolute;
        opacity: 0;
        cursor: pointer;
    }

    /* Create a custom radio button */
    .checkmark {
        position: absolute;
        top: 0;
        left: 0;
        height: 25px;
        width: 25px;
        background-color: rgb(184, 184, 184);
        border-radius: 50%;
    }

    /* On mouse-over, add a grey background color */
    .container:hover input~.checkmark {
        background-color: $color-2; 
    }

    /* When the radio button is checked, add a blue background */
    .container input:checked~.checkmark {
        background-color: $color-2; 
    }

    /* Create the indicator (the dot/circle - hidden when not checked) */
    .checkmark:after {
        content: "";
        position: absolute;
        display: none;
    }

    /* Show the indicator (dot/circle) when checked */
    .container input:checked~.checkmark:after {
        display: block;
    }

    /* Style the indicator (dot/circle) */
    .container .checkmark:after {
        top: 9px;
        left: 9px;
        width: 8px;
        height: 8px;
        border-radius: 50%;
        background: white;
    }
}

@media screen and (max-width: 767px) {
    .container {
        max-width: 100%; 
        padding: 5px; 
    }
} 

.agents-professionnels {
    @media screen and (max-width: 1150px) {
        .blog-page-area {
            .col-md-3,
            .col-md-4 {
                -webkit-box-flex: 0;
                -ms-flex: 0 0 50%;
                flex: 0 0 50%;
                max-width: 50%;
                width: 50%;
            }
        }    
    }
}

@media screen and (max-width: 768px) {
    .blog-page-area {
        .col-md-3,
        .col-md-4 {
            -webkit-box-flex: 0;
            -ms-flex: 0 0 50%;
            flex: 0 0 50%;
            max-width: 50%;
            width: 50%;
        }
    }    
}
@media screen and (max-width: 550px) {
    .blog-page-area {
        .col-md-3,
        .col-md-4 {
            -webkit-box-flex: 0;
            -ms-flex: 0 0 100%!important;
            flex: 0 0 100%!important;
            max-width: 100%!important;
            width: 100%;
        }
    }    
}

.text-secondary {
    max-width: 100%!important;
}

.pagination-area {
    margin-bottom: 35px; 
}

/** Add this class to any anchor tag so it clear the fixed menu (used in forms) */

.jumptarget::before {
    content: "";
    display: block;
    height: $size-nav-height;
    /* fixed header height*/
    margin: -$size-nav-height 0 0;
    /* negative fixed header height */
}

.shadow {
    position: relative;

    &::after {
        content: '';
        z-index: 0;
        width: 100%;
        top: 100%;
        height: 40px;
        position: absolute;
        background: linear-gradient(to bottom, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0));
    }
}

.shadow-02 {
    position: relative;

    &::after {
        content: '';
        z-index: 0;
        width: 100%;
        top: 100%;
        height: 20px;
        position: absolute;
        background: linear-gradient(to bottom, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0));
    }
}

.payment-info {
    label {
        font-family: "Ubuntu";
        font: size 18px;
        font-weight: 500;
    }
}

.error-msg {
    margin: 0;
    width: 100%;
    margin-bottom: 15px;
    padding: 12px 15px;
    background: #ffe085;
    // // font-family: $font-family-3;
    text-transform: none;
    line-height: 21px;
    font-weight: 300;
    color: #f3564a;
    font-size: 15px;
}

.success-msg {
    margin: 0;
    width: 100%;
    margin-bottom: 15px;
    padding: 12px 15px;
    background: #adff77;
    // // font-family: $font-family-3;
    text-transform: none;
    line-height: 21px;
    font-weight: 300;
    color: #1f1f1f;
    font-size: 15px;
}

.info-msg {
    margin: 0;
    width: 100%;
    margin-bottom: 15px;
    padding: 12px 15px;
    background: #b8dfff;
    // // font-family: $font-family-3;
    text-transform: none;
    line-height: 21px;
    font-weight: 300;
    color: #1f1f1f;
    font-size: 15px;
}

div.loading {
    width: 100vw;
    height: 100vh;
    position: fixed;
    top: 0;
    left: 0;
    display: none;
    background-color: rgba(0, 0, 0, 0.7);
    background-size: cover;
    z-index: 9999;

    &.active {
        display: flex;
        justify-content: center;
        align-items: center;
    }
}

#loader {
    position: absolute;
    left: 50%;
    top: 50%;
    z-index: 1;
    width: 150px;
    height: 150px;
    margin: -75px 0 0 -75px;
    border: 16px solid $bg-color-body;
    border-radius: 50%;
    border-top: 16px solid $color-3;
    border-bottom: 16px solid $color-3;
    width: 120px;
    height: 120px;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.8), inset 0px 0px 5px rgba(0, 0, 0, 0.8);
    -webkit-animation: spin 1s linear infinite;
    animation: spin 1s linear infinite;
}

@-webkit-keyframes spin {
    0% {
        -webkit-transform: rotate(0deg);
    }

    100% {
        -webkit-transform: rotate(360deg);
    }
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}

/*****************************************************/


/* Menu (to use with /modules/module-menu.php)
/* Or module-menu-centre.php
/*****************************************************/ 

@import 'modules/menu';
// @import 'menu-centre';

/*****************************************************/


/* Footer (to use with /modules/module-footer.php)
/*****************************************************/

//@import 'styles/footer';

/*****************************************************/


/* Sections (to use with /sections/section-*-*.php)
/*****************************************************/

//@import 'sections/section-header-page';
//@import 'sections/section-text-image';
//@import 'sections/section-bursts';

/*****************************************************/


/* Sections (to use with /modules/module-*-*.php)
/*****************************************************/

@import 'modules/module-slideshow';
//@import 'modules/module-header';
//@import 'modules/module-soumission';

/*******************************************************************************
* COMMON TO ALL PAGES
*/


/*****************************************************/


/* INDEX.PHP
/*****************************************************/


/*******************************************************************************
* X PAGE
*/

.pageX {
    section.header {}
}


/*******************************************************************************
* MOBILE OVERRIDES FOR ALL SECTIONS
*******************************************************************************/

@media screen and (max-width: $size-md-max) {
    /*******************************************************************************
    * COMMON TO ALL PAGES
    */
    /*******************************************************************************
    * X PAGE OVERRIDES
    */
}

@media screen and (max-width: $size-sm-max) {

    /*******************************************************************************
    * COMMON TO ALL PAGES
    */
    section.header {}

    /*******************************************************************************
    * X PAGE OVERRIDES
    */
}

@media screen and (max-width: $size-xs-max) {

    /*******************************************************************************
    * COMMON TO ALL PAGES
    */
    section.header {}

    /*******************************************************************************
    * X PAGE OVERRIDES
    */
}

.footer {
    background-color: #262523 !important;
    border-bottom: 1px solid $color-grey-light;
    color: $color-grey-light;

    .container-fluid {
        width: 100%;
        display: flex;
        align-items: flex-start;
        justify-content: space-around;

        .row {
            width: 100%;
            display: flex;
            align-items: flex-start;
            justify-content: space-around;

            @media screen and (max-width: $size-650-max) {
                flex-direction: column;
                justify-content: center;
                align-items: center;
            }
        }
    }

    .about-company {
        padding-left: .5vw;
        width: 45%;

        i {
            font-size: 25px;
        }

        a {
            &:hover {
                color: #4180CB
            }
        }

        img {
            height: 4vw;
            min-height: 40px;
        }

        p {
            font-size: 15px;
            font-weight: 300;
            line-height: 22px;
            color: $color-grey-light;
            font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
            margin-top: 1.5vw;
        }

        @media screen and (max-width: $size-650-max) {
            width: 90%;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            display: flex;

            p {
                text-align: center;
            }
        }
    }

    .links {
        width: 25%;

        ul {
            list-style-type: none;
            padding-left: 15px;
        }

        li {
            line-height: 1.9vw;
            height: 30px;
            width: 85%;

            a {
                font-size: 15px;
                font-weight: 300;
                // line-height: 22px;
                color: $color-grey-light;
                font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
                transition: color .2s;
                text-transform: none;
                font-weight: 400;
                height: 30px;
                width: 100%;

                &:hover {
                    text-decoration: none;
                    color: #4180CB;
                }
            }
        }

        h5 {
            font-size: .9rem;
        }
    }

    .location {
        width: 25%;

        i {
            font-size: 18px;
        }

        h5 {
            font-size: .9rem;
        }

        a {
            font-size: 15px;
            font-weight: 300;
            line-height: 22px;
            color: $color-grey-light;
            font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
        }
    }
}

@media screen and (max-width: 1200px) {
    .single-product-wrap {
        .product-details-inner {
            .meta-inner {
                li {
                    font-size: 17px!important; 
                }
            }
        }
    }
}

@media screen and (max-width: 767px) {
    .product-details-inner {
        .contact-details-inner h4, h5, p {
            text-align: left!important; 
        }
    }
}

@media screen and (max-width: 1200px) {
    .single-product-wrap .product-meta-bottom {
        font-size: 16px!important;
    }
}

.btn-primary:not(:disabled):not(.disabled):active {
    background-color: #0062cc; 
    border-color: #005cbf; 
}
.btn-success:not(:disabled):not(.disabled):active {
    background-color: #05ac56; 
    border-color: #05ac56; 
} 
.btn-primary {
    border: 0; 
    background-color: #0062cc; 
    border-color: #005cbf; 
}
.btn-success {
    border: 0; 
    background-color: #05ac56; 
    border-color: #05ac56; 
}

#submit_btn {
    min-width: 220px; 
    padding: 10px 20px; 
    border-radius: 2px; 
    background: #ca9000; 
    border: 1px solid #ca9000;
    cursor: pointer;
    transition: .5s;
    &:hover {
        background: #876000; 
        border: 1px solid #876000; 
        transition: .5s; 
    }
} 

.alertify-inner {
    display: flex; 
    align-items: center; 
    justify-content: center; 
    flex-direction: column; 
    padding: 0;
    .alertify-message, p, h3 {
        width: 100%;
        text-align: center; 
        padding: 15px; 
    }
    .alertify-buttons {
        padding: 14px 15px 15px;
        /* background: #F5F5F5; */
        /* border-top: 1px solid #DDD; */
        /* border-radius: 0 0 6px 6px; */
        box-shadow: inset 0 1px 0 #fff!important;
        text-align: center!important;
        width: 100%!important;
    }
}

div.loading {
    width: 100vw;
    height: 100vh;
    position: fixed;
    top: 0;
    left: 0;
    display: none;
    background-color: rgba(0, 0, 0, 0.7);
    background-size: cover;
    z-index: 9999;

    &.active {
        display: flex;
        justify-content: center;
        align-items: center;
    }
}

footer {
    .footer-wrapper {
        display: flex; 
        background: $color-grey-dark;
        padding: 10px;
        .copyright, .sendas_ca { 
            font-size: 13px; 
            margin-right: 10px;
            color: $color-white; 
        } 
        .sendas_ca {
            color: $color-2; 
            &:hover {
                color: $color-black; 
            }
        }
    }
}

#loader {
    position: absolute;
    left: 50%;
    top: 50%;
    z-index: 1;
    width: 150px;
    height: 150px;
    margin: -75px 0 0 -75px;
    border: 16px solid $bg-color-body;
    border-radius: 50%;
    border-top: 16px solid $color-3;
    border-bottom: 16px solid $color-3;
    width: 120px;
    height: 120px;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.8), inset 0px 0px 5px rgba(0, 0, 0, 0.8);
    -webkit-animation: spin 1s linear infinite;
    animation: spin 1s linear infinite;
}

@-webkit-keyframes spin {
    0% {
        -webkit-transform: rotate(0deg);
    }

    100% {
        -webkit-transform: rotate(360deg);
    }
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}